import type { TenantData, UserData } from '../types/generated';

const tenant = ref<TenantData>();
const user = ref<UserData | null>();

interface Context {
    context: {
        tenant: TenantData;
        user: UserData | null;
    }
    setContext: (context: { user: UserData | null; tenant: TenantData }) => void;
}

export default function useContext(): Context {
    function setContext(context: { user: UserData | null; tenant: TenantData }) {
        tenant.value = context.tenant;
        user.value = context.user;
    }

    return {
        context: reactive({
            tenant: computed(() => tenant.value as TenantData),
            user: computed(() => user.value as UserData | null),
        }),
        setContext,
    };
}
