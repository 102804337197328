import { t } from '../plugins/i18n.ts';

export default function useHttpStatus() {
    function getTitle(status, prefixCode = false) {
        return {
            401: (prefixCode ? '401: ' : '')  + t('Unauthenticated'),
            403: (prefixCode ? '403: ' : '')  + t('Forbidden'),
            404: (prefixCode ? '404: ' : '')  + t('Page Not Found'),
            422: (prefixCode ? '422: ' : '')  + t('Validation Error'),
            500: (prefixCode ? '500: ' : '')  + t('Server Error'),
            503: (prefixCode ? '503: ' : '')  + t('Service Unavailable'),
        }[status] || '';
    }

    function getDescription(status) {
        return {
            401: t('Sorry, you must be logged in to access this page.'),
            403: t('Sorry, you are forbidden from accessing this page.'),
            404: t('Sorry, the page you are looking for could not be found.'),
            422: t('Whoops, the given data was invalid.'),
            500: t('Whoops, something went wrong on our servers.'),
            503: t('Sorry, we are doing some maintenance. Please check back soon.'),
        }[status] || '';
    }

    return {
        getTitle,
        getDescription,
    };
}
