import type { App } from 'vue';

const resolveCallback = ref<CallableFunction>();

export const resolver = {
    setResolveCallback: (callback: CallableFunction) => {
        resolveCallback.value = callback;
    },
    resolve: (name: string) => resolveCallback.value!(name),
};

export interface ModalPluginOptions {
    resolve: (name: string) => any
}

export const ModalPlugin = {
    install(app: App, options: ModalPluginOptions) {
        resolver.setResolveCallback(options.resolve);
    },
};
