import { useToast } from 'vue-toastification';
import { t } from '../plugins/i18n.ts';
import AspectNotification from '../components/aspect-notification.vue';

import type { NotificationData } from '../types/generated';

export default function useNotification() {
    const toast = useToast();

    function showToast(notification: NotificationData, timeout?: number) {
        if (!timeout) {
            timeout = notification.type === 'success' ? 3000 : 5000;
        }

        toast({
            component: AspectNotification,
            props: {
                title: notification.title,
                description: notification.description,
                type: notification.type,
            },
            type: notification.type,
        }, {
            timeout,
        });
    }

    function showValidationError(errors: Record<string, string> = {}) {
        showToast({
            title: t('Validation Error'),
            description: Object.keys(errors).length === 1
                ? Object.values(errors)[0]
                : t('Whoops, the given data was invalid.'),
            type: 'error',
        }, 3000);
    }

    const notification = ref();
    const errors = ref();

    watch(errors, () => {
        if (Object.keys(errors.value).length) {
            showValidationError(errors.value);
        }
    });

    watch(notification, () => {
        if (notification.value) {
            showToast(notification.value);
        }
    });

    return {
        notification,
        errors,
        showToast,
        showValidationError,
    };
}

